.Home__academic_zone,
.Home__member {
  margin: 48px auto 0;
}
.news_tab_panel {
  width: 900px;
  /* graid 三行两列 间距16px */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 182px);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}
.Home__academic_zone {
  margin-bottom: 55px;
  .Home_title {
    margin-bottom: 12px;
  }
}
/* .Home__academic_zone .Home_title {
  margin-bottom: 12px;
} */
.Home__academic_zone_content {
  width: 900px;
  height: auto;
  margin: 0 auto;
  position: relative;

  p {
    position: absolute;
    width: 599px;
    top: 34px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-background-13);
    line-height: 20px;
  }

  .image {
    width: 100%;
    height: 100%;
  }
}
/* .Home__academic_zone_content p {
  position: absolute;
  width: 599px;
  top: 34px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-background-13);
  line-height: 20px;
}
.Home__academic_zone_content .image {
  width: 100%;
  height: 100%;
} */

.Home_title h2 {
  font-size: 28px;
  font-weight: 600;
  color: var(--color-font-black);
  text-align: center;
}

.Home_title p {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-font-black-60);
  line-height: 24px;
  text-align: center;
}

.Home_academic_zone_content {
  display: -ms-grid;
  display: grid;
  /* 4列 */
  -ms-grid-columns: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-rows: 350px;
  grid-auto-rows: 350px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.Home__about_us {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;

  h2 {
    font-size: 28px;
    font-weight: 600;
    color: var(--color-white);
    line-height: 40px;
  }

  p {
    font-size: 14px;
    color: var(--color-white);
    line-height: 20px;
    margin-bottom: 18px;
  }
}

.Home__about_us_content {
  margin: 88px auto 0;
  width: 1440px;
  padding-left: 260px;
}

/* .Home__about_us h2 {
  font-size: 28px;
  font-weight: 600;
  color: var(--color-white);
  line-height: 40px;
}
.Home__about_us p {
  font-size: 14px;
  color: var(--color-white);
  line-height: 20px;
  margin-bottom: 18px;
} */
