.news_item__wrapper {
  background-color: var(--color-white);
  overflow: hidden;
}

.news_tab_panel_item {
  background: #ffffff;
  border-radius: 4px;
  margin: 12px 0;
  display: flex;
  position: relative;
  &:hover .image {
    transform: scale(1.1);
  }
}
/* 
.news_tab_panel_item:hover .image {
  transform: scale(1.1);
} */

.tab_panel_item_context {
  flex: 1;
  margin-left: 14px;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-font-black);
    line-height: 22px;

    /* 1行省略号 */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .content {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-font-black-60);
    line-height: 20px;

    /* 2行省略号 */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .time {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: block;
    color: var(--color-font-black-40);
  }
}

/* .tab_panel_item_context .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-font-black);
  line-height: 22px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.tab_panel_item_context .content {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-font-black-60);
  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tab_panel_item_context .time {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  color: var(--color-font-black-40);
} */

.tab_panel_item_image {
  flex-basis: 158px;
  width: 158px;
  height: 158px;
  border-radius: 2px;
  margin-left: 14px;
  margin-right: 14px;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;

  .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
}
/* .tab_panel_item_image .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
} */

.btn {
  position: absolute;
  bottom: 0px;
}
.detail_btn {
  color: var(--color-primary);
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
  