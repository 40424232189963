.tabs {
  margin: 0 auto;
  width: 100%;
}
.tabs__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 31px;
}

.tabs__head .tabs__item {
  padding: 0 28px;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-font-black);
  cursor: pointer;
  position: relative;
}

.tabs__head .tabs__item_active.tabs__item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-primary);
  width: 72px;
  height: 2px;
}

.tabs_children {
  overflow: hidden;
  width: 900px;
  margin: 0 auto;
}

.tabs_content {
  width: 100%;
  height: 100%;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(
    --swiper-wrapper-transition-timing-function,
    ease
  );
  transition-timing-function: var(
    --swiper-wrapper-transition-timing-function,
    initial
  );
  box-sizing: content-box;
}

.tabs_content .tabPanel_wrapper {
  width: 100%;
  flex-shrink: 0;
  height: 100%;
  display: block;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  transition-property: transform;
  margin-right: 100px;
}

.tabs__foot {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
