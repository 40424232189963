.Swiper {
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin: 0 auto;
}

.SwiperSlide {
  width: 100%;
  height: 100%;
  position: relative;
}

.bannerImg {
  width: 1098px;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}


.SwiperButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
  width: 20px;
  height: 20px;
  background-color: rgba(25, 25, 26, 0.05);
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  color: var(--color-white);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(25, 25, 26, 0.5);
  }
}

/* .SwiperButton:hover {
  background-color: rgba(25, 25, 26, 0.5);
} */

.SwiperPrev {
  left: 10px;
}

.SwiperNext {
  right: 10px;
}

.pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  
  span {
    width: 10px;
    height: 4px;
    margin: 0 5px;
    background-color: rgba(25, 25, 26, 0.5);
    cursor: pointer;
  }
}
/* .pagination span {
  width: 10px;
  height: 4px;
  margin: 0 5px;
  background-color: rgba(25, 25, 26, 0.5);
  cursor: pointer;
} */
