.member_wrapper_title {
  margin: 38px auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.member_wrapper_content {
  margin: 0 auto 40px;
  width: 1280px;
  position: relative;
}
.member_swiper {
  overflow: hidden;
  margin: 0 auto;
}

.member_swiper_item {
  width: 216px;
  height: 216px;
  padding: 18px 22px;
  background-color: var(--color-white);
  border-radius: 4px;
  text-align: center;

  .link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .member_swiper_item_img {
    flex: 1;
    width: 100%;
    height: 100px;
    object-fit: contain;
    flex-shrink: 0;
  }
  h4 {
    flex-basis: 40px;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-font-black-45);
    margin: 12px auto 0;
    flex-shrink: 0;
    line-height: normal;
  
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.SwiperButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
  width: 20px;
  height: 20px;
  font-size: 18px;
  background-color: var(--color-white);
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #969799;
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #ebedf0;
  transition: all 0.2s ease-in-out;
}

.SwiperPrev {
  left: 0px;
}

.SwiperNext {
  right: 0px;
}
